<template>
    <section class="payment-record">
        <!-- <div style="padding-top:20px;text-align:center;font-size:20px" >黑名单</div> -->
        <div class=" bg-white">
            <!-- <div style="padding-top:20px;text-align:center;font-size:20px" >黑名单</div> -->
            <div class="top-bar ">
                <el-input v-model="formSearch.keyword" placeholder="请输入姓名/证件号码/联系电话" style="margin: 0 9px 0 7px; width: 300px"
                      size="small"/>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
            <el-button type="primary" size="small" @click="handleAdd">添加</el-button>
              <el-button @click="fanhuiClick" size="small">返回</el-button>
              <span style="width: 1500px;"></span>

            </div>
        </div>
        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getLeasorTableData" :columns="tableColumn"
                   :query="formSearch" :height="790" :notAuto="true" :afterDataReq="afterDataReq">

            <template slot="empty">
                <el-empty/>
            </template>
            <!--陈守亮-2024-1-19-发票暂时隐藏-->
          <el-table-column slot="toolbar" label="操作" width="100">
            <template #default="{ row }">
              <el-button  @click="revokeClick(row)" type="text" >撤销</el-button>
            </template>
          </el-table-column>
        </r-e-table>
      <!-- 添加黑名单弹窗组件 -->
      <dialogAddBlacklist ref="dialogBlacklistref"  @handleSearch="handleSearch"></dialogAddBlacklist>
      <dialogRevokeBlacklist ref="dialogRevokeBlacklistref"  @handleSearch="handleSearch"></dialogRevokeBlacklist>
    </section>
</template>

<script>
import {blacklistTableColumn} from "@/views/rental-management/tenant-management/data";
import {getLeasorList, leasorExport,getBlacklistAPI} from "@/api/tenant-management";
import {downloadByData} from "@/utils/hooks/download";
import {timeFormat} from "@custom/index";
import RETable from "@/components/Gloabl/r-e-table/index.vue";

export default {
    name: "batch-meter-reading",
    data() {
        return {
            tableColumn: blacklistTableColumn(this),
            tableData: [],
            formSearch: {
                keyword: ""
            },
        };
    },
    components: {
      RETable,
        dialogAddBlacklist:()=> import('@/views/rental-management/tenant-management/components/dialog-add-blacklist.vue'),
        dialogRevokeBlacklist:()=> import('@/views/rental-management/tenant-management/components/dialog-revoke-blacklist.vue'),
    },

    mounted() {
        let {params: {data}} = this.$route;
        if (data) {
            const {leasorName} = data;
            if (leasorName) this.formSearch.keyword = leasorName;
        }
        this.handleSearch();
    },
    methods: {
      fanhuiClick(){
        window.history.back();
      },
      getLeasorTableData(params) {
            return getBlacklistAPI(params);
        },
        handleSearch() {
            this.$refs["orderTableRef"].getTableData();
        },
        handleReset() {
            this.formSearch = {keyword: ""};
            Promise.resolve(this.formSearch).then(() => this.handleSearch());
        },
        openDrawer(row) {
            this.$refs["drawerTenantInfo"].openDrawer(row);
        },
        openmessage(row) {
            // 这个调用了
            this.$refs["drawerMessageInfo"].openmessage(row);
        },
        messagePush() {
            // const {keyword} = this.formSearch;
            const loading = this.$loading({
                lock: true,
                text: "正在跳转请您稍等...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            });

        },
        afterDataReq(row) {
            if (row.length === 1) {
                this.openDrawer(row[0]);
            }
            return row;
        },
    //添加黑名弹窗
      handleAdd(){
        this.$refs["dialogBlacklistref"].openblackclick();
      },
    //撤销黑名单弹窗
      revokeClick(row){
        this.$refs["dialogRevokeBlacklistref"].openRevokeclick(row);
      }
    }
};
</script>

<style lang="scss" scoped>
.payment-record {
    padding: VH(15px) VW(15px);

    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(25px);
        padding: VH(15px) VW(15px);
        justify-content: flex-start;
    }

    /deep/ .r-e-table {
        .el-table .cell {
            // white-space: nowrap;
            text-align: center;
        }
    }
}
</style>
